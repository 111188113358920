﻿/*

<![CDATA[

*/
// ReSharper disable FunctionsUsedBeforeDeclared


(function ($) {
    'use strict';

    if (typeof ori != "object") return;

    var self = null;

    ori.addModule("consultants", {
        init: function (options) {
            self.setOptions(options);
        }
    });

    self = ori.consultants;

})(jQuery);

// ReSharper restore FunctionsUsedBeforeDeclared
/* ]]> */